import Image from "next/image";
import { useEffect } from "react";
import { OrderStatus, RetailChain, TimeWindow } from "src/api/types";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import Button from "src/ui-kit/Button";
import IconFont from "src/ui-kit/IconFont";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import { Typography } from "src/ui-kit/Typography";
import { getOrderLink } from "src/utils/getOrderLink";
import { sendCloseOrderInfo, sendOrderClick, sendShowOrderInfo } from "../utils/analytics";
import getTimeWindowText from "../utils/getTimeWindowText";

export interface OrderPanelProps {
  chain: RetailChain;
  status: OrderStatus;
  orderId: string;
  timeWindow: TimeWindow;
  onClose: () => void;
}

const OrderPanel = (props: OrderPanelProps) => {
  const { chain, orderId, status, timeWindow, onClose } = props;

  const theme = useTheme();
  const localize = useLocalize();
  const language = useLang();
  const dataMarkerValue = 'NearestActiveOrder';

  useEffect(() => {
    sendShowOrderInfo();
  }, []);

  const logoUrl = chain.logo.logo;
  const orderUrl = getOrderLink({
    chainHostname: chain.hostname,
    language,
    orderId,
  });

  const handleCloseOrderInfo = () => {
    sendCloseOrderInfo();
    onClose();
  };

  return (
    <StylishBox
      sbs={{
        position: 'fixed',
        left: '50%',
        bottom: '32px',
        zIndex: theme.base.zIndex.orderBar,
        transform: 'translateX(-50%)',
        maxWidth: '1136px',
        width: '100%',
        px: {
          phone: '16px',
          tablet: '32px',
        },
      }}
      dataMarker={dataMarkerValue}
    >
      <StylishBox
        sbs={{
          position: 'relative',
          backgroundColor: theme.base.color.white,
          borderRadius: theme.base.borderRadii.v1,
          p: {
            general: '24px',
            tabletPhone: '12px',
          },
          display: {
            general: 'flex',
          },
          flexDirection: {
            phone: 'column',
          },
          alignItems: {
            desktopTablet: 'center',
          },
          boxShadow: theme.base.boxShadow.v2,
        }}
      >
        <StylishBox
          element="a"
          elementProps={{
            href: orderUrl,
            target: '_blank',
          }}
          sbs={{
            textAlign: {
              phone: 'center',
            },
          }}
        >
          <Image
            src={logoUrl}
            alt={chain.name}
            width={130}
            height={36}
          />
        </StylishBox>

        <StylishBox
          sbs={{
            ml: {
              desktopTablet: '24px',
            },
            mt: {
              phone: '4px',
            },
          }}
        >
          <StylishBox
            sbs={{
              display: 'flex',
              alignItems: 'baseline',
            }}
          >
            <Typography
              sbs={{ mr: '4px' }}
              variant="text3"
            >
              {localize(`order_status_${status}`)}
            </Typography>
            <Typography
              variant="text6"
              dataMarker={`${dataMarkerValue} OrderId`}
            >
              {`№${orderId}`}
            </Typography>
          </StylishBox>
          <Typography
            variant="text6"
            dataMarker={`${dataMarkerValue} TimeSlot`}
          >
            {getTimeWindowText(timeWindow, localize)}
          </Typography>
        </StylishBox>

        <StylishBox
          sbs={{
            display: 'flex',
            alignItems: 'center',
            ml: 'auto',
          }}
        >
          <StylishBox
            sbs={{
              display: {
                phone: 'none',
              },
            }}
          >
            <Button
              variant="primary"
              href={orderUrl}
              target='_blank'
              width='198px'
              onClick={sendOrderClick}
              dataMarker={`${dataMarkerValue} Button`}
            >
              {localize('account.details')}
            </Button>
          </StylishBox>
          <IconFont
            color="gray400"
            name="close"
            sbs={{
              ml: '16px',
              cursor: 'pointer',
              hover: {
                color: theme.base.color.primary,
              },
              position: {
                phone: 'absolute',
              },
              top: '16px',
              right: '16px',
            }}
            elementProps={{
              onClick: handleCloseOrderInfo,
            }}
            dataMarker={`${dataMarkerValue} Close`}
            dataTestId="close-icon"
          />
        </StylishBox>
      </StylishBox>
    </StylishBox>
  );
};

export default OrderPanel;
