import CenteringComponent from "src/components/CenteringComponent";
import Responsive from "src/components/Responsive";
import { SearchBox } from "src/modules/search";
import useCategories from "src/services/categories/useCategories";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme/UseTheme";
import { Typography } from "src/ui-kit/Typography";
import useMainPageSearchLogic from "../../hooks/useMainPageSearchLogic";
import CategoriesTileSlider, { CategoriesTileSliderProps } from "../CategoriesTileSlider/CategoriesTileSlider";
import FakeSearchInput from "../FakeSearchInput/FakeSearchInput";

const SearchAndCategoriesSlider = () => {
  const localize = useLocalize();
  const theme = useTheme();
  const lang = useLang();
  const categories = useCategories();
  const {
    commonSearchBoxProps,
    onSearchInputFocus,
    onMobileInputClick,
    containerRef,
  } = useMainPageSearchLogic();

  const categoriesTileSliderProps: CategoriesTileSliderProps = {
    categories,
    lang,
    theme,
  };

  return (
    <CenteringComponent dataMarker="SearchAndCategoriesSlider">
      <StylishBox
        sbs={{
          py: {
            general: '96px',
            phone: '40px',
          },
          px: {
            phone: '12px',
            tablet: '32px',
          },
          mx: 'auto',
          maxWidth: {
            tablet: '960px',
          },
        }}
      >
        <Typography
          variant="caption2"
          sbs={{
            mb: '12px',
            lineHeight: '12px',
          }}
          color="primary"
          elementProps={{
            ref: containerRef,
          }}
          dataMarker="Search line title"
        >
          {localize('combined_catalogue_of_all_stores')}
        </Typography>

        <Responsive displayOn="desktop">
          <SearchBox
            {...commonSearchBoxProps}
            variant="mainPage"
            searchInputProps={{
              ...commonSearchBoxProps.searchInputProps,
              onFocus: onSearchInputFocus,
            }}
          />
        </Responsive>

        <Responsive displayOn="phoneTablet">
          <FakeSearchInput onClick={onMobileInputClick} />
          {commonSearchBoxProps.searchIsOpened && (
            <SearchBox
              {...commonSearchBoxProps}
              variant="mobile"
            />
          )}
        </Responsive>

        {categories.length > 0 && (
          <StylishBox sbs={{ mt: '32px' }}>
            <Responsive displayOn="desktop">
              <CategoriesTileSlider
                {...categoriesTileSliderProps}
              />
            </Responsive>

            <Responsive displayOn="phoneTablet">
              <CategoriesTileSlider
                {...categoriesTileSliderProps}
                itemPerRow={3}
              />
            </Responsive>
          </StylishBox>
        )}
      </StylishBox>
    </CenteringComponent>
  );
};

export default SearchAndCategoriesSlider;
