import classNames from 'classnames';
import { useState } from 'react';
import Button from 'src/ui-kit/Button';
import { useTheme } from 'src/ui-kit/theme';
import { useProducts } from '../../api/useProducts';
import { useLocalize } from '../../services/localize/useLocalize';
import getChainPromoLink from '../../utils/getChainPromoLink';
import CenteringComponent from "../CenteringComponent";
import MainProductsPromotionSlider from './MainProductsPromotionSlider/MainProductsPromotionSlider';
import { StorePromotionsSectionStyled } from './StorePromotionsSection.styles';

interface Props {
  chain: string;
  lang: string;
  logo: string;
  hostname: string;
}

function StorePromotionsSection(props: Props) {
  const { chain, lang, logo, hostname } = props;
  const [isImpressed, setIsImpressed] = useState(false);
  const localize = useLocalize();
  const theme = useTheme();
  const { products, productsIsFetching } = useProducts(isImpressed, chain, lang);

  const isSliderVisible = !isImpressed || productsIsFetching || !!products.length;

  return (
    <StorePromotionsSectionStyled
      theme={theme}
      className={classNames('StorePromotionsSection', {
        StorePromotionsSection__container: isSliderVisible,
      })}
      partialVisibility
      onImpressed={() => setIsImpressed(true)}
    >
      {isSliderVisible && (
        <CenteringComponent>
          <section className='StorePromotionsSection__inner'>
            <MainProductsPromotionSlider
              products={products}
              isFetching={productsIsFetching || !isImpressed}
              chain={chain}
              logo={logo}
              hostname={hostname}
            />
            <div className="StorePromotionsSection__storeButtonMobile">
              <Button
                width="100%"
                variant='secondary'
                onClick={() => window.open(getChainPromoLink(hostname, lang), '_blank')}
              >
                {localize('all_promotions')}
              </Button>
            </div>
          </section>
        </CenteringComponent>
      )}
    </StorePromotionsSectionStyled>
  );
}

export default StorePromotionsSection;
