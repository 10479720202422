import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { desktop, phone, tablet } from '../../styles/media';

export const HomeHeroStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => css(`
  background: ${theme.base.color.gray100};

  & .HomeHero__container {
    display: flex;
    max-width: 1136px;
    height: 320px;
    margin: 0 auto;
  }

  & .HomeHero__textBlock {
    padding-top: 54px;
    flex-grow: 1;
    min-width: 0;
  }

  & .HomeHero__benefitList {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    margin-top: 1px;
    margin-left: -9px;
  }

  & .HomeHero__benefitItem {
    flex: 0 0 240px;
    margin-top: 22px;
    margin-left: 9px;
  }

  & .HomeHero__imgBlock {
    flex-shrink: 0;
    position: relative;
    width: 448px;
    height: 320px;
    padding-left: 98px;
    margin: 0 16px;
    user-select: none;
    overflow: hidden;
  }

  & .HomeHeroContainer__customerImage {
    z-index: 1;
    position: relative;
  }

  @media ${desktop} {
    & .HomeHero__imgBlock::before {
      content: '';
      position: absolute;
      top: -72px;
      left: 0;
      width: 448px;
      height: 448px;
      background: ${theme.base.color.white};
      border-radius: 50%;
    }
  }

  @media ${tablet} {
    & .HomeHero__container {
      position: relative;
      max-width: 960px;
      height: 374px;
      padding: 0 32px;
    }

    & .HomeHero__textBlock {
      padding-top: 39px;
    }

    & .HomeHero__benefitList {
      margin-top: 22px;
    }

    & .HomeHero__imgBlock {
      position: absolute;
      right: 60px;
      bottom: 0;
      width: 182px;
      height: 250px;
      padding-left: 0;
      margin: 0;
    }
  }

  @media ${phone} {
    & .HomeHero__container {
      max-width: 432px;
      height: auto;
      padding: 0 16px;
    }

    & .HomeHero__imgBlock {
      display: none;
    }

    & .HomeHero__textBlock {
      padding: 40px 0 35px;
    }

    & .HomeHero__benefitList {
      display: block;
      margin: 0;
    }

    & .HomeHero__benefitItem {
      margin: 18px 0 0;
    }
  }
`));
