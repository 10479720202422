import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme/UseTheme';
import { Typography } from '../ui-kit/Typography';

interface Props {
  smallHeading: string;
  bigHeading: string;
}

function DoubleHeading(props: Props) {
  const theme = useTheme();

  return (
    <StylishBox
      sbs={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.base.color.gray100,
        pt: {
          desktop: '96px',
          tablet: '72px',
          phone: '32px',
        },
      }}
    >
      <Typography
        element='h3'
        variant='caption2'
        color='primary'
        dataMarker='Small Heading'
      >
        {props.smallHeading}
      </Typography>

      <Typography
        variant='h2'
        dataMarker='Big Heading'
      >
        {props.bigHeading}
      </Typography>
    </StylishBox>
  );
}

export default DoubleHeading;
