import useLocalize from "src/services/localize/useLocalize";
import IconFont from "src/ui-kit/IconFont";
import { useTheme } from "src/ui-kit/theme";

interface Props {
  onClick(): void;
}

const FakeSearchInput = ({ onClick }: Props) => {
  const theme = useTheme();
  const localize = useLocalize();

  return (
    <div
      data-marker="Search Input"
      onClick={onClick}
      className='FakeSearchInput'
    >
      {localize('search_box_placeholder')}
      <div className="FakeSearchInput__icon">
        <IconFont name='loupe' />
      </div>

      <style jsx>{`
        .FakeSearchInput {
          height: 40px;
          padding: 10px 40px 10px 16px;
          border-radius: 3px;
          border: 1px solid ${theme.base.color.gray300};
          background: ${theme.base.color.white};
          color: ${theme.base.color.gray500};
          cursor: pointer;
          position: relative;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .FakeSearchInput__icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          pointer-events: none;
          color: ${theme.base.color.gray400};
        }
      `}</style>
    </div>
  );
};

export default FakeSearchInput;
