import { Fragment } from "react";
import { Category } from "src/api/types";
import NavButtons from "src/components/NavButtons/NavButtons";
import Responsive from "src/components/Responsive";
import Slider from "src/components/SliderV2";
import { useSlider } from "src/components/SliderV2/helpers/useSlider";
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Theme } from "src/ui-kit/theme/types";
import { Typography } from "src/ui-kit/Typography";
import { groupArrayElements } from "src/utils/groupArrayElements";
import CategoryTile from "../CategoryTile/CategoryTile";

export interface CategoriesTileSliderProps {
  categories: Category[];
  theme: Theme;
  lang: string;
  itemPerRow?: number;
}

const CategoriesTileSlider = (props: CategoriesTileSliderProps) => {
  const { categories, theme, lang, itemPerRow = 4 } = props;
  const localize = useLocalize();

  const { sliderRef, controls, sliderControls } = useSlider({
    fullWidthScroll: true,
    withSwiperMode: false,
  });

  const categoriesInSlideCount = itemPerRow * 2;
  const slidesGroup = groupArrayElements(categories, categoriesInSlideCount);
  const withNavButtons = categories.length > categoriesInSlideCount;

  return (
    <Fragment>
      <StylishBox
        sbs={{
          display: 'flex',
          justifyContent: {
            general: 'space-between',
            phone: 'center',
          },
          alignItems: 'center',
          mb: '8px',
        }}
      >
        <Typography
          variant="h2"
          sbs={{
            fontSize: {
              general: '40px',
              phone: '32px',
            },
            lineHeight: {
              general: '54px',
              phone: '40px',
            },
          }}
        >
          {localize('categories.popular')}
        </Typography>

        {withNavButtons && (
          <Responsive displayOn='tabletDesktop'>
            <NavButtons
              dataMarker='Navigation buttons'
              controls={controls}
            />
          </Responsive>
        )}
      </StylishBox>

      <StylishBox
        sbs={{
          mx: {
            general: `-${theme.base.space.v2}`,
          },
        }}
      >
        <Slider
          sliderRef={sliderRef}
          controls={sliderControls}
          dataMarker="CategoriesTileSlider"
        >
          {
            slidesGroup.map((slides, index) => (
              <StylishBox
                key={`group_${index}`}
                sbs={{
                  display: 'flex',
                  flexWrap: {
                    general: 'wrap',
                    phone: 'nowrap',
                  },
                  width: {
                    desktopTablet: '100%',
                  },
                  flexShrink: '0',
                }}
              >
                {slides.map((category) => (
                  <StylishBox
                    key={`${category.id}_${index}`}
                    sbs={{
                      p: {
                        general: theme.base.space.v2,
                      },
                      width: {
                        desktopTablet: `${100 / itemPerRow}%`,
                      },
                    }}
                  >
                    <CategoryTile
                      key={category.id}
                      category={category}
                      language={lang}
                      theme={theme}
                    />
                  </StylishBox>
                ))}
              </StylishBox>
            ))
          }
        </Slider>
      </StylishBox>
    </Fragment>
  );
};

export default CategoriesTileSlider;
