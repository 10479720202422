export function groupArrayElements<T>(array: T[], elementsPerGroup: number): T[][] {
  if (elementsPerGroup <= 0) {
    return [];
  }

  const result: T[][] = [];
  for (let i = 0; i < array.length; i += elementsPerGroup) {
    result.push(array.slice(i, i + elementsPerGroup));
  }

  return result;
}
