import { Fragment } from 'react';
import { COUNTRY } from 'src/data/constants';
import Container from 'src/ui-kit/Container/Container';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme';
import personalizeString from 'src/utils/personalize';
import { useLocalize } from '../../services/localize/useLocalize';
import * as media from '../../styles/media';
import { Typography } from '../../ui-kit/Typography';
import AppLinks from '../AppLinks/AppLinks';


const imageSizes = ['phone', 'tablet', 'desktop'];

const getImageSrc = ({ size }) => `https://img4.zakaz.ua/mainsite-images/app_banner_${COUNTRY}_${size}.png`;

interface Props {
  chainsCount: number;
}

const AppSection = (props: Props) => {
  const {
    chainsCount,
  } = props;
  const localize = useLocalize();
  const theme = useTheme();

  return (
    <StylishBox
      dataMarker="App Section"
      sbs={{
        backgroundColor: theme.base.color.primary,
        overflow: 'hidden',
      }}
    >
      <Container
        sbs={{
          mx: 'auto',
          px: {
            tablet: '32px',
            phone: '16px',
          },
          maxWidth: {
            general: '1440px',
            tablet: '960px',
            phone: '432px',
          },
          position: 'relative',
          py: {
            desktop: '76px',
            tablet: '40px',
          },
          pt: {
            phone: '16px',
          },
          height: {
            desktop: '320px',
            tablet: '228px',
          },
        }}
      >

        <Fragment>
          <Typography
            element='h4'
            color='white'
            sbs={{
              fontSize: {
                general: '24px',
                desktop: '32px',
              },
              lineHeight: {
                general: '24px',
                desktop: '32px',
              },
              fontFamily: theme.base.fontFamily.accented,
              mb: {
                general: '10px',
                tabletPhone: '8px',
              },
              textAlign: {
                phone: 'center',
              },
            }}
            dataMarker='Big Title'
          >
            {personalizeString(localize('stores_in_one_app_title'), [chainsCount])}
          </Typography>
          <Typography
            color='white'
            sbs={{
              mb: {
                general: '24px',
                tabletPhone: '16px',
              },
              maxWidth: {
                desktop: '560px',
                tablet: '371px',
              },
              fontSize: {
                general: '14px',
                desktop: '16px',
              },
              lineHeight: '24px',
              textAlign: {
                phone: 'center',
              },
            }}
            dataMarker='Text'
          >
            {personalizeString(localize('stores_in_one_app_desc'), [chainsCount])}
          </Typography>
        </Fragment>
        <AppLinks
          centerPhone
          buttonColor='green'
          dataMarker='AppSection'
        />
        <StylishBox
          sbs={{
            position: {
              desktopTablet: 'absolute',
            },
            right: {
              desktop: '-73px',
              tablet: '40px',
            },
            top: '0px',
          }}
        >
          <picture>
            {imageSizes.map(size => (
              <source
                key={size}
                srcSet={getImageSrc({ size })}
                media={media[size]}
              />
            ))}
            <StylishBox
              sbs={{
                display: 'block',
                mx: 'auto',
              }}
              element='img'
              elementProps={{
                src: getImageSrc({ size: 'desktop' }),
                alt: 'app banner',
              }}
            />
          </picture>
        </StylishBox>
      </Container>
    </StylishBox>
  );
};

export default AppSection;
