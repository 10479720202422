import Image from 'next/image';
import Link from 'next/link';
import { useMediaQuery } from 'react-responsive';
import { Category } from "src/api/types";
import { defaultProductImage } from 'src/fixtures';
import { getCategoriesUrl, sendCategoryClick } from 'src/modules/ubercatalog';
import { phone } from 'src/styles/media';
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Theme } from 'src/ui-kit/theme/types';
import { Typography } from 'src/ui-kit/Typography';

interface CategoryTileProps {
  category: Category;
  language: string;
  theme: Theme;
  dataMarker?: string;
}

const CategoryTile = (props: CategoryTileProps) => {
  const { category, language, theme, dataMarker = 'CategoryTile' } = props;

  const isPhone = useMediaQuery({ query: phone });
  const categoryId = category.id;

  return (
    <Link
      href={getCategoriesUrl({ lang: language, categoryId })}
      onClick={() => {
        sendCategoryClick({
          page: 'landing',
          place: 'category_category_tiles',
          category_id: categoryId,
        });
      }}
      data-marker={dataMarker}
    >
      <StylishBox
        sbs={{
          overflow: 'hidden',
          position: 'relative',
          p: {
            general: '16px',
            phone: '12px 8px',
          },
          width: {
            general: '100%',
            phone: '91px',
          },
          height: {
            general: '115px',
            phone: '155px',
          },
          backgroundColor: category.image_url?.main_category_preview_background,
          borderRadius: theme.base.borderRadii.v1,
          hover: {
            boxShadow: theme.base.boxShadow.v1,
          },
          border: theme.base.border.v1,
          borderColor: theme.base.color.gray200,
        }}
      >
        <StylishBox
          sbs={{
            position: 'absolute',
            top: {
              desktopTablet: '0',
            },
            right: '0',
            bottom: {
              phone: '0',
            },
            left: {
              phone: '0',
            },
          }}
        >
          <Image
            src={category.image_url?.main_category_preview || defaultProductImage}
            alt={category.title}
            width={isPhone ? 91 : 132}
            height={isPhone ? 79 : 115}
            date-marker={`${dataMarker} image`}
          />
        </StylishBox>
        <Typography variant="text3"
          sbs={{
            lineHeight: {
              general: '19px',
              phone: '16px',
            },
            fontSize: {
              phone: '12px',
            },
            maxWidth: {
              desktopTablet: '120px',
            },
            wordBreak: {
              phone: 'break-word',
            },
          }}
          dataMarker={`${dataMarker} title`}
        >
          {category.title}
        </Typography>
      </StylishBox>
    </Link>
  );
};

export default CategoryTile;
