import React, { Fragment } from 'react';
import { desktop, tablet_desktop } from '../styles/media';
import { Typography } from '../ui-kit/Typography';

interface Props {
  step: string;
  heading: string;
  text: string;
  image: string;
}

const HowStep = (props: Props) => {
  const { step, heading, text, image } = props;

  return (
    <Fragment>
      <div className="HowStep__image" data-marker="Step Image"/>
      <Typography
        variant='h4'
        element='p'
        color='white'
        dataMarker="Step Number"
      >
        {step}
      </Typography>

      <Typography
        element='h3'
        variant='text4'
        sbs={{ mt: '8px'}}
        color='white'
        dataMarker='Step Title'
      >
        {heading}
      </Typography>

      <Typography
        color='white'
        sbs={{ mt: '4px'}}
        dataMarker='Step Text'
      >
        {text}
      </Typography>
      <style jsx>{`
        @media ${tablet_desktop} {
          .HowStep__image {
            background-image: url(${image});
            background-repeat: no-repeat;
            background-position: right 50px top 25px;
            position: absolute;
            width: 30%;
            height: 125px;
            right: 0;
          }
        }
        @media ${desktop} {
          .HowStep__image {
            background-position: right 0 top 25px;
            background-position: 0 0;
            position: relative;
            height: 120px;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default HowStep;
