import { ReactNode, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  onImpressed: () => void;
  children: ReactNode;
  partialVisibility?: boolean;
  className?: string;
}

const Impressionable = (props: Props) => {
  const { children, onImpressed = () => {}, partialVisibility, className } = props;
  const [isImpressed, setImpressed] = useState(false);

  const handleChange = (isVisible: boolean) => {
    if (isVisible && !isImpressed) {
      onImpressed();
      setImpressed(true);
    }
  };

  const { ref } = useInView({
    onChange: handleChange,
    threshold: partialVisibility ? 0.2 : 1, // Adjust based on `partialVisibility` (20% or full)
  });

  return (
    <div className={className} data-marker="Impressionable" ref={ref}>
      {children}
    </div>
  );
};



export default Impressionable;
