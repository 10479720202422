import { fetcher } from 'src/api/fetch';
import { Banner } from 'src/api/types';
import useSWR from 'swr';
import { getDataFromResponse } from './api/getDataFromResponse';

export function useBanners(): Banner[] {
  const swrFetcher = async () => {
    const url = `/partners/?platform=corporate&place=main`;

    const response = await fetcher<Banner[]>(url);

    return getDataFromResponse(response, []);
  };

  const { data = [] } = useSWR<Banner[]>('partners', swrFetcher);

  return data;
}
