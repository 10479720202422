const birds = [
  'green-bird',
  'red-bird',
  'black-bird',
];

const getCustomBadge = (chain, ribbons) => {
  if (chain !== 'auchan') return null;

  const cssClass = ribbons && ribbons[0] && ribbons[0].css_class;

  if (!cssClass) return null;

  for (const bird of birds) {
    if (cssClass.includes(bird)) {
      return bird;
    }
  }

  return null;
};

export default getCustomBadge;