import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import { desktop, phone, tablet, tablet_desktop } from '../../styles/media';
import Impressionable from '../Impressionable';

export const StorePromotionsSectionStyled = styled(Impressionable, {
  shouldForwardProp: (prop) => prop !== 'theme',
})<StyledThemeProps>(({ theme }) => `
  .StorePromotionsSection__inner {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .StorePromotionsSection__storeButtonContainer {
    padding: 32px 16px 56px;
  }
  .StorePromotionsSection__storeButtonContainer {
    display: flex;
    justify-content: center;
  }
  &.StorePromotionsSection__container:nth-child(even) {
    background-color: ${theme.base.color.gray100};
  }
  &.StorePromotionsSection__container:nth-child(odd) {
    background-color: ${theme.base.color.white};
  }
  .StorePromotionsSection__storeButtonMobile {
    display: flex;
    padding: 32px 16px 56px 0px;
    justify-content: center;
  }
  .StorePromotionsSection__info {
    display: flex;
    justify-content: center;
  }

  @media ${phone} {
    .StorePromotionsSection__inner {
      padding-left: 16px;
    }
  }

  @media ${tablet} {
    .StorePromotionsSection__storeButtonMobile {
      width: 225px;
      margin: 0 auto;
      padding: 32px 0 0;
    }
    .StorePromotionsSection__inner {
      padding-bottom: 80px;
      padding-left: 33px;
    }
  }

  @media ${tablet_desktop} {
    .StorePromotionsSection__storeButton {
      width: 190px;
      display: flex;
    }
  }

  @media ${desktop} {
    .StorePromotionsSection__inner {
      padding-bottom: 95px;
    }

    .StorePromotionsSection__buttonContainer {
      display: flex;
      position: absolute;
      right: 0;
      padding-left: 0;
      padding-right: 0;
      align-self: center;
    }
    .StorePromotionsSection__storeButtonMobile {
      display: none;
    }
  }
`);
