import { AddressManagementBar, sendDeliveryAddressPopupShow, useAmSize } from 'src/modules/address-management';
import { useModals } from 'src/modules/modals';
import { sendChainCardClick, UserRetailsInfo } from 'src/modules/retails';
import Container from 'src/ui-kit/Container/Container';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme';

const HomeRetails = () => {
  const modals = useModals();
  const componentsSize = useAmSize();
  const theme = useTheme();
  const place = 'main';

  const handleAmButtonClick = () => {
    modals.openModal('addressManagement');
    sendDeliveryAddressPopupShow(place);
  };

  const handleRetailInfoCardClick = (isAvailable: boolean) => {
    sendChainCardClick(place, isAvailable);
  };

  return (
    <StylishBox
      className="HomeRetails"
      sbs={{
        pb: '40px',
        backgroundColor: theme.base.color.gray100,
      }}
    >
      <Container>
        <AddressManagementBar
          withIconAnimation
          size={componentsSize}
          onAmButtonClick={handleAmButtonClick}
        />

        <UserRetailsInfo
          size={componentsSize}
          onClickOpenAm={handleAmButtonClick}
          onRetailInfoCardClick={handleRetailInfoCardClick}
        />
      </Container>
    </StylishBox>
  );
};

export default HomeRetails;
