interface OrderLinkParams {
  chainHostname: string;
  language: string;
  orderId: string;
}

export const getOrderLink = (params: OrderLinkParams) => {
  const { chainHostname, language, orderId } = params;
  return `https://${chainHostname}/${language}/account/orders/${orderId}`;
};
