import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import useSWR from "swr";
import { fetcher } from "./fetch";
import { Product, ProductResponse } from "./types";


export function useProducts(isImpressed: boolean, chain: string, language: string) {
  const args = [language, chain, 'promotion_products'];

  const swrFetcher = async ([language, chain]: string[]) => {
    const url = `/stores/default/products/filter_by_category/promotions/?per_page=30&sort=popularity_desc&only_items=true`;

    const res = await fetcher<ProductResponse>(url, {
      headers: {
        'x-chain': chain,
      },
      language,
    });

    return getDataFromResponse(res, { results: [] });
  };

  const { data, error } = useSWR<ProductResponse>(isImpressed ? args : null, swrFetcher);

  return {
    products: data?.results || [] as Product[],
    productsIsFetching: isImpressed && !data && !error,
  };
}
