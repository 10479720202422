import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import IconFont from '../../ui-kit/IconFont';
import { Typography } from '../../ui-kit/Typography';

interface BenefitProps {
  iconName: 'basket' | 'price' | 'fresh' | 'clock';
  text: string;
}

const Benefit = (props: BenefitProps) => {
  const {
    iconName,
    text,
  } = props;

  return (
    <StylishBox
      className='Benefit'
      sbs={{
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <StylishBox
        sbs={{
          display: 'flex',
          width: '20px',
          height: '20px',
          flexShrink: '0',
          textAlign: 'center',
        }}
      >
        <IconFont name={iconName} size='16px' color='gray500' sbs={{m: 'auto'}}/>
      </StylishBox>

      <Typography variant='text6'>{text}</Typography>
    </StylishBox>
  );
};

export default Benefit;
