import { FocusEventHandler, useRef } from "react";
import { HEADER_HEIGHT, MARGIN_UNDER_HEADER } from "src/modules/header";
import { useSearchBoxLogic } from "src/modules/search";
import { disableBodyScroll } from "src/utils/system/scrollLock";

const useMainPageSearchLogic = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const searchBoxLogic = useSearchBoxLogic('landing');
  const {
    openSearch,
    ...searchBoxProps
  } = searchBoxLogic;

  const scrollToElement = () => {
    return new Promise((resolve) => {
      if (elementRef && elementRef.current) {
        const offsetTop = HEADER_HEIGHT + MARGIN_UNDER_HEADER;
        const elementPosition = elementRef.current.getBoundingClientRect().top - 1 + window.scrollY;

        // Прокручиваем к элементу
        window.scrollTo({
          top: elementPosition - offsetTop,
          behavior: 'smooth',
        });

        // Отслеживаем завершение прокрутки с помощью requestAnimationFrame
        const checkIfScrollFinished = () => {
          const currentScrollPosition = window.scrollY;

          // Проверяем, достигнута ли целевая позиция
          if (Math.abs(currentScrollPosition - (elementPosition - offsetTop)) < 1) {
            resolve(true);  // Прокрутка завершена, возвращаем true
          } else {
            requestAnimationFrame(checkIfScrollFinished);
          }
        };

        // Запускаем цикл отслеживания завершения прокрутки
        requestAnimationFrame(checkIfScrollFinished);
      } else {
        resolve(false);  // На случай, если elementRef не существует
      }
    });
  };

  const handleInputFocus: FocusEventHandler<HTMLInputElement> = async (e) => {
    const originalFocusHandler = searchBoxProps.searchInputProps.onFocus;
    const scrollFinished = await scrollToElement();

    if (scrollFinished && originalFocusHandler) {
      originalFocusHandler(e);
    }
  };

  const handleMobileInputClick = () => {
    disableBodyScroll();
    openSearch();
  };

  return {
    commonSearchBoxProps: searchBoxProps,
    onSearchInputFocus: handleInputFocus,
    onMobileInputClick: handleMobileInputClick,
    containerRef: elementRef,
  };
};

export default useMainPageSearchLogic;
