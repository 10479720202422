import { fetcher } from "src/api/fetch";
import { OrderDetailInfoDto } from "src/api/types";
import useUserProfile from "src/services/userProfile/useUserProfile";
import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import useSWR from "swr";

interface UseNearestActiveOrderResult {
  nearestActiveOrder: OrderDetailInfoDto | null;
  nearestActiveOrderIsFetching: boolean;
}

export const useNearestActiveOrder = (): UseNearestActiveOrderResult => {
  const { userProfile } = useUserProfile();
  const userPhone = userProfile?.login?.phone;

  const { data, error } = useSWR(
    () => userPhone ? [ userPhone, 'nearest_active'] : null,
    getNearestActiveOrder,
  );

  const nearestActiveOrderIsFetching = data === undefined && error === undefined && !!userPhone;
  const nearestActiveOrder = data || null;

  return {
    nearestActiveOrder,
    nearestActiveOrderIsFetching,
  };

  async function getNearestActiveOrder() {
    const defaultValue = null;

    const response = await fetcher<OrderDetailInfoDto | null>(
      `/user/orders/nearest_active/`,
      {
        credentials: 'include',
      },
      defaultValue,
    );

    return getDataFromResponse(response, defaultValue);
  }
};
