import { RetailChain } from "src/api/types";
import { getChainsMap } from "src/utils/chains/getChainsMap";
import { useLocalStorage } from 'usehooks-ts';
import { useNearestActiveOrder } from "../hooks/useNearestActiveOrder";
import OrderPanel from "./OrderPanel";

interface NearestActiveOrderProps {
  chains: RetailChain[];
}

const NearestActiveOrder = ({ chains }: NearestActiveOrderProps) => {
  const [localStorageOrderId, setLocalStorageOrderIdOrderId] = useLocalStorage<string | null>('nearestActiveOrderId', null);
  const { nearestActiveOrder } = useNearestActiveOrder();

  const isVisibleOrderInfo = nearestActiveOrder && localStorageOrderId !== nearestActiveOrder.id;

  if (!isVisibleOrderInfo) {
    return null;
  }

  const chainsMap = getChainsMap(chains);
  const { chain_id, id, status, time_window } = nearestActiveOrder;
  const chain = chainsMap[chain_id];

  return (
   <OrderPanel
    chain={chain}
    orderId={id}
    status={status}
    timeWindow={time_window}
    onClose={() => setLocalStorageOrderIdOrderId(id)}
   />
  );
};

export default NearestActiveOrder;
