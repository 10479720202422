import { Fragment } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import { useLocalize } from '../services/localize/useLocalize';
import { desktop, tablet_desktop } from '../styles/media';
import { Typography } from '../ui-kit/Typography';
import CenteringComponent from './CenteringComponent';
import HowStep from './HowStep';

const HowSection = () => {
  const theme = useTheme();
  const localize = useLocalize();

  const steps = [
    {
      heading: localize('prices_are_same_as_in_store'),
      text: localize('price_is_same_as_that_of_retailer'),
      image: 'https://img4.zakaz.ua/price.svg',
    },
    {
      heading: localize('wide_range_of_products'),
      text: localize('assortment_of_goods_is_same_as_in_market'),
      image: 'https://img4.zakaz.ua/catalogue.svg',
    },
    {
      heading: localize('careful_ordering'),
      text: localize('selection_of_only_best_products'),
      image: 'https://img4.zakaz.ua/order.svg',
    },
    {
      heading: localize('prompt_home_delivery'),
      text: localize('best_choice_of_courier_route'),
      image: 'https://img4.zakaz.ua/delivery.svg',
    },
  ];
  return (
    <Fragment>
      <div className='HowSection__Container' data-marker='Why us'>
        <CenteringComponent>
          <section className="HowSection">
            <Typography
              element='h3'
              variant='caption2'
              color='white'
              align={{ general: 'center', desktop: 'left' }}
              sbs={{ mb: { general: '8px', desktopTablet: '16px' } }}
            >
              {localize('save_your_time_nerves')}
            </Typography>
            <Typography
              element='h2'
              color='white'
              align={{ general: 'center', desktop: 'left' }}
              variant={{ general: 'h2', phone: 'h3' }}
              sbs={{ mb: { general: '16px', tabletPhone: '24px' } }}
            >
              {localize('why_us')}
            </Typography>
            <ol className='HowSection__stepList'>
              {
                steps.map((step, index) => (
                  <li key={step.heading} className="HowSection__stepItem">
                    <HowStep
                      step={`0${index+1}`}
                      heading={step.heading}
                      text={step.text}
                      image={step.image}
                    />
                  </li>
                ))
              }
            </ol>
          </section>
        </CenteringComponent>
      </div>
      <style jsx>{`
        .HowSection__Container {
          background-color: ${theme.base.color.primary};
        }
        .HowSection {
          background-color: inherit;
          padding: 56px 16px 24px;
        }
        .HowSection__stepItem {
          padding-bottom: 32px;
        }
        @media ${tablet_desktop} {
          .HowSection {
            padding: 70px 32px 10px;
          }
          .HowSection__stepItem {
            width: 69%;
            padding-bottom: 60px;
          }
        }
        @media ${desktop} {
          .HowSection {
            padding: 80px 32px 25px;
          }
          .HowSection__stepList {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .HowSection__stepItem {
            width: 40%;
            display: flex;
            flex-direction: column;
            padding-bottom: 45px;
            border-bottom: none;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default HowSection;
