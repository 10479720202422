import { LocalizeFunc } from '../../../services/localize/useLocalizeService';

import { TimeWindow } from 'src/api/types';
import convertDateToString from 'src/utils/date/convertDateToString';

const getTimeWindowText = (
  timeWindow: TimeWindow,
  localize: LocalizeFunc,
) => {
  const datePart = convertDateToString(new Date(timeWindow.date), localize);

  return `${localize('wait_delivery')} ${datePart.toLocaleLowerCase()} ${localize('slot_text_from')} ${timeWindow.time_range}`;
};

export default getTimeWindowText;
