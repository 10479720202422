import shuffle from 'lodash/shuffle';
import { useMemo } from 'react';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useChains } from '../api/swr';
import { RetailChain } from '../api/types';
import useLang from '../services/language/useLang';
import { useLocalize } from '../services/localize/useLocalize';
import DoubleHeading from './DoubleHeading';
import StorePromotionsSection from './StorePromotionsSection/StorePromotionsSection';

interface Props {
  chains: RetailChain[];
}

function PromotionSection(props: Props) {
  const lang = useLang();
  const { chains } = useChains({ fallbackData: props.chains, language: lang });
  const localize = useLocalize();

  const shuffledChains = useMemo(() => shuffle(chains), [chains]);

  return (
    <StylishBox
      element='section'
      dataMarker='Promotion Section'
    >
      <DoubleHeading
        smallHeading={localize('choose_profitably')}
        bigHeading={localize('promotional_items')}
      />

      {
        shuffledChains.map(chain =>
          <StorePromotionsSection
            key={chain.id}
            chain={chain.id}
            logo={chain.logo.logo}
            lang={lang}
            hostname={chain.hostname}
          />,
        )
      }
    </StylishBox>
  );
}

export default PromotionSection;
