export const sendShowOrderInfo = () => {
  window.dataLayer.push({
    event: "order_info_shown",
  });
};


export const sendCloseOrderInfo = () => {
  window.dataLayer.push({
    event: "order_info_closed",
  });
};

export const sendOrderClick = () => {
  window.dataLayer.push({
    event: "order_info_click",
  });
};
